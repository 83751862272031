/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import FileInput from '../../../../fileInput'
import { useSmartFormContext } from '../../../../../context/smart-form.context'
import { smartFormConst } from '../../../../../utils/action.constant'
import { loadFinalValue } from '../../../../../utils/form.util'
import { toSnakeCase } from '../../../../../utils/common.util'
/**
 *  dataTypeId 51
 *  dataTypeName PDF Scan
 *
 * */
const supportedFiles = ['application/pdf']
const PdfScan = ({
  field,
  isDisabled = true,
  isRequired,
  onValidate,
  isFileUpload,
  onDelete,
  onError,
}) => {
  const {
    state: { fields: formFields },
    dispatch,
  } = useSmartFormContext()
  const fields = !!formFields[field.form_id] ? formFields[field.form_id] : {}

  const onChange = file => {
    const { url } = file

    if (!!isRequired) {
      if (!!url) {
        onValidate(true)
      } else {
        onValidate(false)
      }
    } else {
      onValidate(true)
    }
    dispatch({
      type: smartFormConst.SET_FIELD_VALUE,
      fieldId: field.field_id,
      formId: field.form_id,
      value: loadFinalValue(field, url),
    })
  }
  let value = !!fields[field.field_id] ? fields[field.field_id].field_value : ''
  useEffect(() => {
    if (!!fields[field.field_id] && fields[field.field_id].field_value) {
      onChange({ url: fields[field.field_id].field_value })
    }
  }, [value])

  return (
    <FileInput
      className={`pdf-scan-field-${toSnakeCase(field.field_name)}`}
      field={field}
      onChange={onChange}
      isDisabled={isDisabled}
      placeholder=".pdf"
      value={value}
      isFileUpload={isFileUpload}
      supportedFiles={supportedFiles}
      onError={onError}
      onReset={() => {
        onValidate(false)
      }}
      onDelete={onDelete}
    />
  )
}

export default PdfScan
