/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Input, VStack, HStack } from '@chakra-ui/react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { startsWith } from 'lodash'

import {
	getMessageUniqueId,
	toSnakeCase,
} from '../../../../../utils/common.util'
import { useSmartFormContext } from '../../../../../context/smart-form.context'
import './business.scss'
import { smartFormConst } from '../../../../../utils/action.constant'
const BuissnessCard = ({
	field,
	isDisabled = true,
	isRequired,
	onValidate,
	isDesignation = false,
	onError,
}) => {
	const {
		state: { fields: formFields },
		dispatch,
	} = useSmartFormContext()
	const fields = !!formFields[field.form_id] ? formFields[field.form_id] : {}

	const [contactName, setContactName] = useState('')
	const [contactEmail, setContactEmail] = useState('')
	const [contactPhone, setContactPhone] = useState('')
	const [alternatePhone, setAlternatePhone] = useState('')
	const [landlinePhone, setLandlinePhone] = useState('')
	const [contactDesignation, setContactDesignation] = useState('')
	const [contactOrganization, setContactOrganization] = useState('')
	const [contactFax, setContactFax] = useState('')
	const [addressArea, setAddressArea] = useState('')
	const [addressBuilding, setAddressBuilding] = useState('')
	const [addressCity, setAddressCity] = useState('')
	const [pinCode, setPinCode] = useState('')
	const [contactState, setContactState] = useState('')
	const [country, setCountry] = useState('')
	const [isEmailValid, setEmailValidation] = useState(false)
	const [isPhoneValid, setIsPhoneValid] = useState(false)

	useEffect(() => {
		onError(true)
	}, [])

	useEffect(() => {
		if (
			!!fields[field.field_id] &&
			!!fields[field.field_id].field_value &&
			!!fields[field.field_id].field_value.isFromEdit
		) {
			const fieldValue = fields[field.field_id].field_value.value
			setContactName(fieldValue?.contact_name)
			setContactEmail(fieldValue?.contact_email)
			setContactPhone(fieldValue?.contact_phone)
			setAlternatePhone(fieldValue?.contact_alternative_phone)
			setLandlinePhone(fieldValue?.contact_landline)
			setContactDesignation(fieldValue?.contact_designation)
			setContactFax(fieldValue?.contact_fax)
			setAddressArea(fieldValue?.address_area)
			setContactOrganization(fieldValue?.contact_organization)
			if (!!fieldValue?.contact_address) {
				setAddressBuilding(fieldValue?.contact_address.building)
				setAddressCity(fieldValue?.contact_address.city)
				setPinCode(fieldValue?.contact_address.pincode)
				setContactState(fieldValue?.contact_address.state)
				setCountry(fieldValue?.contact_address.country)
			}
		}
	}, [fields, field.field_id])

	const {
		field_id,
		form_id,
		field_reference_id,
		field_name,
		data_type_id,
		data_type_category_id,
	} = field

	useEffect(() => {
		if (
			!!contactName &&
			!!contactDesignation &&
			!!contactEmail &&
			!isEmailValid &&
			!isPhoneValid
		) {
			onError(false)
			onValidate(true)
		} else {
			onError(true)
			onValidate(false)
		}
	}, [contactName, contactDesignation, contactPhone, contactEmail])

	const onContactChange = () => {
		let contact_value = {
			contact_name: contactName,
			contact_email: contactEmail,
			contact_phone: contactPhone.substring(contactPhone.length - 10),
			contact_alternative_phone: alternatePhone.substring(
				alternatePhone.length - 10
			),
			contact_landline: landlinePhone,
			contact_fax: contactFax,
			contact_designation: contactDesignation,
			contact_organization: contactOrganization,
			address_area: addressArea,
			contact_address: {
				building: addressBuilding,
				city: addressCity,
				pincode: pinCode,
				state: contactState,
				country: country,
			},
		}
		if (
			!!contactName &&
			!!contactDesignation &&
			!isEmailValid &&
			!isPhoneValid
		) {
			onError(false)
			onValidate(true)
		} else {
			onError(true)
			onValidate(false)
		}
		dispatch({
			type: smartFormConst.SET_FIELD_VALUE,
			fieldId: field.field_id,
			formId: field.form_id,
			value: {
				final: {
					form_id,
					field_id,
					field_reference_id,
					field_name,
					field_data_type_id: data_type_id,
					field_data_type_category_id: data_type_category_id,
					field_value: contact_value,
					message_unique_id: getMessageUniqueId(),
				},
				field_value: contact_value,
			},
		})
	}
	const handlePhoneValidation = phone => {
		let isValidPhone = !new RegExp(
			/^(?:(?:\+|0{0,2})91(\s*|[\-])?|[0]?)?([6789]\d{2}([ -]?)\d{5}([ -]?)\d{4})$/
		).test(phone)
		setIsPhoneValid(isValidPhone)
	}

	const handleEmail = e => {
		setContactEmail(e.target.value)
		const createRegExp = (str, opts) =>
			new RegExp(str.raw[0].replace(/\s/gm, ''), opts || '')
		const regEx = createRegExp`
      ^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|
      (\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|
      (([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$`
		let isValid = !regEx.test(e.target.value)
		setContactEmail(e.target.value)
		setEmailValidation(isValid)
	}

	return (
		<VStack
			spacing={1}
			className={`business-card-input-field-${toSnakeCase(field_name)}`}
		>
			<Input
				type='text'
				// required={isRequired}
				//autoFocus={true}
				isDisabled={isDisabled}
				borderRadius='md'
				size='sm'
				onBlur={() => {
					onContactChange()
				}}
				id={'contactName'}
				placeholder='Contact name *'
				value={contactName}
				onChange={e => {
					if (!e.target.value.charAt(0).match(/\s/))
						setContactName(e.target.value)
				}}
			/>
			<Input
				type='email'
				// autoFocus={true}
				isDisabled={isDisabled}
				borderRadius='md'
				size='sm'
				onBlur={() => {
					onContactChange()
				}}
				focusBorderColor={isEmailValid ? 'red.500' : 'primary'}
				id={'contactEmail'}
				placeholder='Email ex.vijay example@domain.com *'
				value={contactEmail}
				isInvalid={isEmailValid}
				onChange={e => {
					handleEmail(e)
				}}
			/>
			<Input
				type='text'
				// required={isRequired}
				// autoFocus={true}
				isDisabled={isDisabled}
				borderRadius='md'
				size='sm'
				onBlur={() => {
					onContactChange()
				}}
				id={'contactDesignation'}
				placeholder='Designation *'
				value={contactDesignation}
				onChange={e => {
					setContactDesignation(e.target.value)
				}}
			/>
			<HStack justifyContent='space-evenly' width='full'>
				<PhoneInput
					disabled={isDisabled}
					value={contactPhone}
					onBlur={() => {
						onContactChange()
					}}
					country='in'
					// // specialLabel={"Phone Number"}
					onChange={phone => {
						setContactPhone(phone)
						handlePhoneValidation(phone)
					}}
					onlyCountries={[
						'au',
						'in',
						'us',
						'bd',
						'gb',
						'de',
						'fr',
						'lb',
						'hk',
						'sk',
					]}
					// countryCodeEditable={false}
					name='phone_number'
					placeholder='Phone ex.+91 0000000000 *'
					enableSearch
					buttonStyle={{ border: 'none' }}
					buttonClass='rounded '
					inputStyle={{
						margin: 'auto',
						border: 'none',
						boxShadow: '0 2px 6px rgb(0,0,0,0.05)',
						borderRadius: '5px',
						width: '100%',
					}}
					isValid={(inputNumber, country, countries) => {
						return countries.some(country => {
							return new RegExp(
								/^(?:(?:\+|0{0,2})91(\s*|[\-])?|[0]?)?([6789]\d{2}([ -]?)\d{5}([ -]?)\d{4})$/
							).test(inputNumber)
						})
					}}
				/>
				<PhoneInput
					disabled={isDisabled}
					value={alternatePhone}
					onChange={phone => {
						setAlternatePhone(phone)
					}}
					country='in'
					onlyCountries={[
						'au',
						'in',
						'us',
						'bd',
						'gb',
						'de',
						'fr',
						'lb',
						'hk',
						'sk',
					]}
					placeholder='Alternative phone ex.+91 0000000000'
					name='Alternate Phone Number'
					enableSearch
					buttonStyle={{ border: 'none' }}
					onBlur={() => {
						onContactChange()
					}}
					buttonClass='rounded '
					inputStyle={{
						margin: 'auto',
						border: 'none',
						boxShadow: '0 2px 6px rgb(0,0,0,0.05)',
						borderRadius: '5px',
						width: '100%',
					}}
					isValid={(inputNumber, country, countries) => {
						return countries.some(country => {
							return new RegExp(
								/^(?:(?:\+|0{0,2})91(\s*|[\-])?|[0]?)?([6789]\d{2}([ -]?)\d{5}([ -]?)\d{4})$/
							).test(inputNumber)
						})
					}}
				/>
			</HStack>
			<HStack justifyContent='space-evenly' width='full'>
				<Input
					isDisabled={isDisabled}
					type='number'
					// autoFocus={true}
					borderRadius='md'
					size='sm'
					id={'fax'}
					placeholder='Fax ex.+040 0000000000'
					value={landlinePhone}
					onChange={e => {
						setLandlinePhone(e.target.value)
					}}
					name='Fax'
				/>
			</HStack>
			<HStack justifyContent='space-evenly' width='full'>
				<Input
					type='text'
					// required={isRequired}
					// autoFocus={true}
					isDisabled={isDisabled}
					borderRadius='md'
					size='sm'
					onBlur={() => {
						onContactChange()
					}}
					id={'addressArea'}
					placeholder='Area'
					value={addressArea}
					onChange={e => setAddressArea(e.target.value)}
				/>
				<Input
					type='text'
					// required={isRequired}
					// autoFocus={true}
					isDisabled={isDisabled}
					borderRadius='md'
					size='sm'
					onBlur={() => {
						onContactChange()
					}}
					id={'addressBuilding'}
					placeholder='Building'
					value={addressBuilding}
					onChange={e => setAddressBuilding(e.target.value)}
				/>
			</HStack>
			<HStack justifyContent='space-evenly' width='full'>
				<Input
					type='text'
					// required={isRequired}
					// autoFocus={true}
					isDisabled={isDisabled}
					borderRadius='md'
					size='sm'
					onBlur={() => {
						onContactChange()
					}}
					id={'addressCity'}
					placeholder='City'
					value={addressCity}
					onChange={e => setAddressCity(e.target.value)}
				/>
				<Input
					type='text'
					// required={isRequired}
					// autoFocus={true}
					isDisabled={isDisabled}
					borderRadius='md'
					size='sm'
					onBlur={() => {
						onContactChange()
					}}
					// id={'pinCode'}
					placeholder='Pin code'
					value={pinCode}
					onChange={e => setPinCode(e.target.value)}
				/>
			</HStack>
			<Input
				type='text'
				// required={isRequired}
				// autoFocus={true}
				isDisabled={isDisabled}
				borderRadius='md'
				size='sm'
				onBlur={() => {
					onContactChange()
				}}
				id={'contactState'}
				placeholder='State name'
				value={contactState}
				onChange={e => setContactState(e.target.value)}
			/>
		</VStack>
	)
}

BuissnessCard.propTypes = {
	field: PropTypes.object.isRequired,
}

export default BuissnessCard
