/* eslint-disable react-hooks/exhaustive-deps */
import { Input } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useSmartFormContext } from '../../../../../context/smart-form.context';
import { useLoadUserIpAddress } from '../../../../../hooks/shared.hooks';
import { smartFormConst } from '../../../../../utils/action.constant';
import { toSnakeCase } from '../../../../../utils/common.util';
import { loadFinalValue } from '../../../../../utils/form.util';
/**
 * dataTypeID 53
 * dataTypeName  Ip Address
 *
 */
const IpAddress = ({ field, onValidate }) => {
  const { mutate, data } = useLoadUserIpAddress();
  const { dispatch } = useSmartFormContext();

  useEffect(() => {
    mutate();
  }, [mutate]);

  useEffect(() => {
    onValidate(true);
    dispatch({
      type: smartFormConst.SET_FIELD_VALUE,
      fieldId: field.field_id,
      formId: field.form_id,
      value: loadFinalValue(field, data),
    });
  }, [field, data]);

  return (
    <Input
      className={`ip-address-field-${toSnakeCase(field.field_name)}`}
      autoComplete="off"
      type="text"
      value={data || '0.0.0.0'}
      isDisabled={true}
      placeholder={field.field_place_holder||`Enter ${field.field_name}`}
      borderRadius="md"
      size="sm"
    />
  );
};

export default IpAddress;
