/* eslint-disable react-hooks/exhaustive-deps */
/*
Check wether bot configuration is enable or not for the field
to get the validation config to use while upload data

useMutation>>>
useloadExcelBotValidation();

field_validation_enabled === 1

*/

import React, { useEffect, useState } from 'react'
import FileInput from '../../../../fileInput'
import { useSmartFormContext } from '../../../../../context/smart-form.context'
import { useAuthContext } from '../../../../../context/auth.context'
import { smartFormConst } from '../../../../../utils/action.constant'
import { loadFinalValue } from '../../../../../utils/form.util'
import { validateExcelBot } from '../../../../../config/excel_validation_utils'
import {
	useLoadExcelBotValidationScheme,
	useValidateAnnexureExcel,
} from '../../../../../hooks/form.hooks'
import { pushFileToS3 } from '../../../../../utils/aws.util'
import { Spinner, Progress, Center } from '@chakra-ui/react'
import { toSnakeCase } from '../../../../../utils/common.util'
/**
 *  dataTypeId 52
 *  dataTypeName Excel Document
 *
 * */

const supportedFiles = [
	'application/vnd.ms-excel',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	'application/vnd.ms-excel.sheet.binary.macroenabled.12',
	'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
	'application/vnd.ms-excel.sheet.macroenabled.12',
	'application/vnd.ms-excel.sheet.macroEnabled.12',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
]

const annexureFieldArray = [
	'11182i',
	'11746i',
	'12415i',
	'12949i',
	'12963i',
	'12993i',
	'12891i',
	'10489i',
	'11748i',
	'11029i',
	'26440i',
	'219367i',
	'347187i',
]

const ExcelDocument = ({
	fieldList,
	field,
	isDisabled = true,
	isRequired,
	onValidate,
	onError,
	isFileUpload,
	onDelete,
	activity,
}) => {
	const {
		state: { fields: formFields },
		dispatch,
	} = useSmartFormContext()
	const fields = !!formFields[field.form_id] ? formFields[field.form_id] : {}
	const {
		state: { authData },
	} = useAuthContext()
	const { mutate, isLoading } = useLoadExcelBotValidationScheme()
	const { mutate: mutateValidateAnnexureExcel } = useValidateAnnexureExcel()
	const [isUpload, setIsUpload] = useState(false)
	const onChange = file => {
		const { url, isValid, msg } = file
		if (!!field.field_validation_enabled) {
			/// TODO to the excel validation call
			onDelete(false)
			if (!isValid) {
				onValidate(false)
				onError(msg)
			}
		}
		if (!!isRequired) {
			if (!!url) {
				onError('')
				onValidate(true)
			} else {
				onValidate(false)
				onError(msg)
			}
		} else {
			onError('')
			onValidate(true)
		}
		dispatch({
			type: smartFormConst.SET_FIELD_VALUE,
			fieldId: field.field_id,
			formId: field.form_id,
			value: loadFinalValue(field, url),
		})
	}

	const onMountValidate = file => {
		const { url, isValid, msg } = file
		if (!!field.field_validation_enabled) {
			/// TODO to the excel validation call
			onDelete(false)
			if (!isValid) {
				onValidate(false)
				onError(msg)
			}
		}
		if (!!isRequired) {
			if (!!url) {
				onError('')
				onValidate(true)
			} else {
				onValidate(false)
				onError(msg)
			}
		} else {
			onError('')
			onValidate(true)
		}
	}

	const onReset = () => {
		onValidate(false)
		onError('')
	}

	const uploadFile = async file => {
		// console.log('valid true');
		const blob = file.slice(0, file.size, file.type)
		const newFile = new File([blob], file.name.replace(/ /gi, '-'), {
			type: blob.type,
		})
		let result = await pushFileToS3(newFile)
		isFileUpload(false)
		setIsUpload(false)
		const [{ url }] = await Promise.all([result])
		const is_annexure = annexureFieldArray.includes(field.field_id + 'i')
		if (is_annexure) {
			validateAnnexExcel(url)
		} else {
			onChange({ url: url, isValid: true, msg: '' })
		}
	}
	const validateAnnexExcel = path => {
		mutateValidateAnnexureExcel(
			{
				bucket_url: path,
			},
			{
				onSuccess: async res => {
					if (res.status === 200) {
						onChange({ url: path, isValid: true, msg: '' })
					} else {
						isFileUpload(false)
						setIsUpload(false)
						onValidate(false)
						callError(res.response, true)
						callError(res.response, true)
					}
				},
				onError: async err => {
					console.log(err)
				},
			}
		)
	}
	const validateExcel = file => {
		isFileUpload(true)
		setIsUpload(true)
		mutate(
			{ bot_id: 0, form_id: field.form_id, field_id: field.field_id },
			{
				onSuccess: async res => {
					const {
						data: { status, response },
					} = res
					if (status === 200) {
						if (field.field_inline_data) {
							const {
								flag_enable_excel_validation_on_edit,
								excel_validation_start_date,
							} = field.field_inline_data
							let createdDate = new Date(
								activity?.activity_datetime_created || ''
							)
							let validationDate = new Date(excel_validation_start_date)
							let isCreatedBefore = +createdDate > +validationDate
							if (
								flag_enable_excel_validation_on_edit &&
								isCreatedBefore &&
								!!activity?.activity_datetime_created
							) {
								validateExcelBot({
									schema: !!response.bot_operations
										? response.bot_operations.validate_excel
										: [],
									file: file[0],
									fieldList: fields,
									onSuccess: uploadFile,
									onError: e => {
										isFileUpload(false)
										setIsUpload(false)
										onValidate(false)
										callError(e)
										callError(e)
									},
								})
							} else if (!activity?.activity_datetime_created) {
								validateExcelBot({
									schema: !!response.bot_operations
										? response.bot_operations.validate_excel
										: [],
									file: file[0],
									fieldList: fields,
									onSuccess: uploadFile,
									onError: e => {
										isFileUpload(false)
										setIsUpload(false)
										onValidate(false)
										callError(e)
										callError(e)
									},
								})
							} else {
								uploadFile(file[0])
							}
						} else {
							validateExcelBot({
								schema: !!response.bot_operations
									? response.bot_operations.validate_excel
									: [],
								file: file[0],
								fieldList: fields,
								onSuccess: uploadFile,
								onError: e => {
									isFileUpload(false)
									setIsUpload(false)
									onValidate(false)
									callError(e)
									callError(e)
								},
							})
						}
					}
				},
			}
		)
	}

	const callError = (e, st = false) => {
		let isMand = !!e && !!e.is_mandatory ? ` and column is mandatory ` : ``
		let isDType =
			!!e && !!e.column_datatype ? ` and datatype as ${e.column_datatype}` : ``
		let errText =
			!!e && !!e.cell_value
				? `column ${e.cell_x.toUpperCase()} name should be ${e.cell_value} ` +
				  isMand +
				  isDType
				: ''
		onError(
			!!e && !!e.cell_value
				? `Uploaded Excel is invalid. Please upload valid Excel - ` + errText
				: !!st
				? e
				: `Uploaded Excel is invalid. Please upload valid Excel`
		)
	}

	useEffect(() => {
		if (!!fields[field.field_id] && fields[field.field_id].field_value) {
			onChange({ url: fields[field.field_id].field_value })
		}
	}, [])

	useEffect(() => {
		if (!!fields[field.field_id] && fields[field.field_id].field_value) {
			onMountValidate({ url: fields[field.field_id].field_value })
		}
	}, [formFields])

	return (
		<>
			{isUpload ? (
				<Progress
					mx={2}
					colorScheme={localStorage.getItem('color')}
					hasStripe
					isAnimated
					size='sm'
					isIndeterminate
				/>
			) : (
				<FileInput
					className={`excel-document-input-${toSnakeCase(field.field_name)} ${
						fields[field.field_id] ? fields[field.field_id].field_value : ''
					}`}
					field={field}
					onChange={onChange}
					fieldList={fieldList}
					isDisabled={isDisabled}
					placeholder='.xlsb, .xlsx, .xls'
					supportedFiles={supportedFiles}
					onError={onError}
					onReset={onReset}
					value={
						fields[field.field_id] ? fields[field.field_id].field_value : ''
					}
					onExcelValidation={validateExcel}
					isFileUpload={isFileUpload}
					onDelete={onDelete}
				/>
			)}
		</>
	)
}

export default ExcelDocument
