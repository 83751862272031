/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useSmartFormContext } from '../../../../../context/smart-form.context';
import { smartFormConst } from '../../../../../utils/action.constant';
import { loadFinalValue } from '../../../../../utils/form.util';
import FileInput from '../../../../fileInput';

/**
 *  dataTypeId 56
 *  dataTypeName Outlook Document
 *
 * */

const supportedFiles = ['application/vnd.ms-outlook', 'message/rfc822', ''];
const OutlookDocument = ({
  field,
  isDisabled = true,
  isRequired,
  onValidate,
  onError,
  isFileUpload,
  onDelete,
}) => {
  const {
    state: { fields: formFields },
    dispatch,
  } = useSmartFormContext();
  const fields = !!formFields[field.form_id] ? formFields[field.form_id] : {};

  const onChange = file => {
    const { url } = file;
    if (!!isRequired) {
      if (!!url) {
        onValidate(true);
      } else {
        onValidate(false);
      }
    } else {
      onValidate(true);
    }
    dispatch({
      type: smartFormConst.SET_FIELD_VALUE,
      fieldId: field.field_id,
      formId: field.form_id,
      value: loadFinalValue(field, url),
    });
  };

  useEffect(() => {
    if (!!fields[field.field_id] && fields[field.field_id].field_value) {
      onChange({ url: fields[field.field_id].field_value });
    }
  }, []);

  return (
    <FileInput
      className={`outlook-message-input-${field.id} ${
        fields[field.field_id] ? fields[field.field_id].field_value : ''
      }`}
      field={field}
      onChange={onChange}
      isDisabled={isDisabled}
      placeholder=".outlooks, .eml, .msg"
      supportedFiles={supportedFiles}
      value={fields[field.field_id] ? fields[field.field_id].field_value : ''}
      onError={onError}
      isFileUpload={isFileUpload}
      onReset={() => {
        onValidate(false);
        onError('');
      }}
      onDelete={onDelete}
    />
  );
};

export default OutlookDocument;
