import {
	Input,
	NumberDecrementStepper,
	NumberIncrementStepper,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	Textarea,
	VStack,
} from '@chakra-ui/react'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useSmartFormContext } from '../../../../../context/smart-form.context'
import { useSaveDocumentRepo } from '../../../../../hooks/form.hooks'
import { smartFormConst } from '../../../../../utils/action.constant'
import { toSnakeCase } from '../../../../../utils/common.util'
import { loadFinalValue } from '../../../../../utils/form.util'
import FileInput from '../../../../fileInput'

/**
 * // TODO: ImplementDocument respository specific fields and services and handle the data accordingly.
 *
 */

/**
 *
    {
      form_id: this.props.formData.form_id,
      field_id: field.id,
      field_reference_id: field.field_reference_id,
      field_name: field.config.label,
      field_data_type_id: field.config.elementType,
      field_data_type_category_id: field.config.elementCatType,
      data_type_combo_id: 0,
      data_type_combo_value: "0",
      field_value: field.config.fileUploadData,
      message_unique_id: utils.getMessageUniqueId(
        this.props.formData.asset_id
      ),
    }
 *
*/

const DocumentRepositoryField = ({
	value,
	onChange,
	isDisabled = true,
	field,
	activity: selectedActivity,
	isRequired,
	onValidate,
	isFileUpload,
	onDelete,
	onError,
}) => {
	const {
		state: { fields: formFields },
		dispatch,
	} = useSmartFormContext()
	const fields = !!formFields[field.form_id] ? formFields[field.form_id] : {}
	const { mutate: saveDocumentMutate } = useSaveDocumentRepo()
	const { field_id } = field

	const {
		document_description,
		document_title,
		document_version,
		product_reference,
		url_path,
	} =
		!!fields[field_id] && !!fields[field_id].field_value
			? fields[field_id].field_value
			: {
					document_description: '',
					document_title: '',
					document_version: '',
					product_reference: { activity_name: '' },
					url_path: '',
			  }

	let activity_name =
		!!product_reference && !!product_reference?.activity_name
			? product_reference?.activity_name
			: ''

	const [documentTitle, setDocumentTitile] = useState(document_title)
	const [documentDescription, setDocumentDescription] =
		useState(document_description)
	const [documentVersion, setDocumentVersion] = useState(document_version)
	const [urlPath, setUrlPath] = useState(url_path)

	useEffect(() => {
		setDocumentTitile(document_title)
		setDocumentDescription(document_description)
		setDocumentVersion(document_version)
		setUrlPath(url_path)
	}, [document_title, document_description, document_version, url_path])

	const buildDocumentAddParams = formUrl => {
		let formattedValue = {}
		var n = formUrl.url.lastIndexOf('/')
		var file_name = n > -1 ? formUrl.url.substr(n + 1, formUrl.url.length) : ''
		formattedValue = {
			product: documentTitle,
			document_title: documentTitle,
			document_desc: documentDescription,
			file_title: file_name,
			url_path: formUrl.url,
			activity_id: selectedActivity ? selectedActivity.activity_id : 0,
			device_os_id: 5,
		}
		return formattedValue
	}

	const saveDocument = params => {
		saveDocumentMutate(
			{
				...params,
			},
			{
				onSuccess: async data => {
					dispatch({
						type: smartFormConst.SET_FIELD_VALUE,
						fieldId: field.field_id,
						formId: field.form_id,
						value: loadFinalValue(field, {
							document_title: documentTitle,
							document_description: documentDescription,
							document_version: documentVersion,
							document_url: params.url_path,
							document_id: data.document_id,
							productWorkflow: {
								activity_id: selectedActivity
									? selectedActivity.activity_id
									: 0,
								activity_name: selectedActivity
									? selectedActivity.activity_title
									: '',
							},
						}),
					})
				},
				onError: async data => {
					console.log(data)
				},
			}
		)
	}

	const onFileSelect = formUrl => {
		if (
			!!formUrl &&
			!!documentTitle &&
			!!documentDescription &&
			!!documentVersion
		) {
			onValidate(true)
		} else {
			onValidate(false)
		}
		setUrlPath(formUrl.url)
		let doc_params = buildDocumentAddParams(formUrl)
		saveDocument(doc_params)
	}
	return (
		<VStack
			className={`document-repository-field-${toSnakeCase(field.field_name)}`}
		>
			<Input
				type='text'
				isDisabled={true}
				placeholder={`Product Reference `}
				borderRadius='md'
				value={
					selectedActivity ? selectedActivity.activity_title : activity_name
				}
				size='sm'
			/>
			<Input
				type='text'
				// required={isRequired}
				//autoFocus={true}
				isDisabled={isDisabled}
				placeholder={`Enter  Document Title `}
				borderRadius='md'
				size='sm'
				value={documentTitle}
				onChange={e => setDocumentTitile(e.target.value)}
			/>
			<Textarea
				type='text'
				isDisabled={isDisabled}
				placeholder={`Enter Document Description`}
				borderRadius='md'
				size='sm'
				rows={3}
				resize='vertical'
				value={documentDescription}
				onChange={e => setDocumentDescription(e.target.value)}
			/>
			<NumberInput
				onChange={e => setDocumentVersion(e)}
				value={documentVersion || 0}
				isDisabled={isDisabled}
				placeholder='Document Version'
			>
				<NumberInputField />
				<NumberInputStepper>
					<NumberIncrementStepper />
					<NumberDecrementStepper />
				</NumberInputStepper>
			</NumberInput>
			<FileInput
				field={field}
				onChange={onFileSelect}
				isDisabled={isDisabled}
				placeholder='.pdf'
				value={urlPath}
				isFileUpload={isFileUpload}
				onDelete={onDelete}
				onError={onError}
			/>
		</VStack>
	)
}
DocumentRepositoryField.propTypes = {
	field: PropTypes.object.isRequired,
}
export default DocumentRepositoryField
