/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import FileInput from '../../../../fileInput'
import { useSmartFormContext } from '../../../../../context/smart-form.context'
import { smartFormConst } from '../../../../../utils/action.constant'
import { loadFinalValue } from '../../../../../utils/form.util'

/**
 *  dataTypeId 73
 *  dataTypeName Dropbox Document
 *
 * */
const supportedFiles = [
  'application/zip',
  'application/x-zip-compressed',
  'multipart/x-zip',
]
const ZipFileAttachmentField = ({
  field,
  isDisabled = true,
  isRequired,
  onValidate,
  onError,
  isFileUpload,
  onDelete,
}) => {
  const {
    state: { fields: formFields },
    dispatch,
  } = useSmartFormContext()
  const fields = !!formFields[field.form_id] ? formFields[field.form_id] : {}

  const onChange = file => {
    const { url } = file
    if (!!isRequired) {
      if (!!url) {
        onValidate(true)
      } else {
        onValidate(false)
      }
    } else {
      onValidate(true)
    }
    dispatch({
      type: smartFormConst.SET_FIELD_VALUE,
      fieldId: field.field_id,
      formId: field.form_id,
      value: loadFinalValue(field, url),
    })
  }

  let value = fields[field.field_id] ? fields[field.field_id].field_value : ''

  useEffect(() => {
    if (!!fields[field.field_id] && fields[field.field_id].field_value) {
      onChange({ url: fields[field.field_id].field_value })
    }
  }, [value])

  return (
    <FileInput
      field={field}
      onChange={onChange}
      isDisabled={isDisabled}
      placeholder=".zip"
      supportedFiles={supportedFiles}
      value={value}
      onError={onError}
      onReset={() => {
        onValidate(false)
        onError('')
      }}
      isFileUpload={isFileUpload}
      onDelete={onDelete}
    />
  )
}

export default ZipFileAttachmentField
