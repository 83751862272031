/* eslint-disable react-hooks/exhaustive-deps */
import {
	Box,
	Flex,
	HStack,
	Icon,
	IconButton,
	Text,
	Button,
	AlertDialog,
	AlertDialogBody,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogContent,
	AlertDialogOverlay,
	useDisclosure,
} from '@chakra-ui/react'
import React, { useCallback, useEffect, useState } from 'react'
import {
	AiFillFileExcel,
	AiFillFileImage,
	AiFillFilePdf,
	AiFillFilePpt,
	AiFillFileText,
	AiFillFileWord,
	AiFillFileZip,
	AiFillMail,
	AiFillVideoCamera,
} from 'react-icons/ai'
import { ImAttachment } from 'react-icons/im'
import { MdClose, MdCloudDownload } from 'react-icons/md'
import { useSmartFormContext } from '../../../../../context/smart-form.context'
import { useLanguageContext } from '../../../../../context/locale.context'
import { smartFormConst } from '../../../../../utils/action.constant'
import { toSnakeCase } from '../../../../../utils/common.util'
import { loadFinalValue } from '../../../../../utils/form.util'
import FileInput from '../../../../fileInput'
import { useLoadExcelBotValidationScheme } from '../../../../../hooks/form.hooks'
import { Progress, Center } from '@chakra-ui/react'
import { dropBoxValidateExcelBot } from '../../../../../config/excel_validation_utils'
import { pushFileToS3, downloadAwsFile } from '../../../../../utils/aws.util'
import {
	GA_CATEGORY_CUSTOM_INPUT,
	sectionDetailedTrack,
} from '../../../../../containers/repository/repo.utils'
/**
 *  dataTypeId 76
 *  dataTypeName Dropbox Document
 *
 * */

const DropboxDocumentField = ({
	field,
	isDisabled = true,
	isRequired,
	onValidate,
	isFileUpload,
	onDelete,
	onError,
}) => {
	const {
		state: { fields: formFields },
		dispatch,
	} = useSmartFormContext()
	const {
		state: { locale },
	} = useLanguageContext()
	const fields = !!formFields[field.form_id] ? formFields[field.form_id] : {}

	const cancelRef = React.useRef()
	const { mutate, isLoading } = useLoadExcelBotValidationScheme()
	const [isUpload, setIsUpload] = useState(false)
	const { isOpen, onOpen, onClose } = useDisclosure()

	const [files, setFiles] = useState([])
	const [count, setCount] = useState(1)

	const getFileType = file => {
		const fileType = file.file_type ? file.file_type.toLowerCase() : ''
		if (
			!!['gif', 'jpg', 'jpeg', 'png', 'ico'].find(item =>
				fileType.includes(item)
			)
		) {
			return AiFillFileImage
		} else if (!!['pdf'].find(item => fileType.includes(item))) {
			return AiFillFilePdf
		} else if (
			!![
				'xlsx',
				'xlsm',
				'xlsb',
				'xltx',
				'xltm',
				'xls',
				'csv',
				'xlc',
				'sheet',
				'application/vnd.ms-excel',
			].find(item => fileType.includes(item))
		) {
			return AiFillFileExcel
		} else if (
			!!['word', 'msword', 'doc', 'txt', 'docx'].find(item =>
				fileType.includes(item)
			)
		) {
			return AiFillFileWord
		} else if (
			!!['eml', 'mail', 'msg', 'message', 'oft', 'ost'].find(item =>
				fileType.includes(item)
			) ||
			!fileType
		) {
			return AiFillMail
		} else if (
			!!['mspowerpoint', 'powerpoint', 'mspowerpoint', 'presentation'].find(
				item => fileType.includes(item)
			)
		) {
			return AiFillFilePpt
		} else if (
			!!['mp4', 'mpg', 'mkv', 'avi', 'mov', 'wmv'].find(item =>
				fileType.includes(item)
			)
		) {
			return AiFillVideoCamera
		} else if (
			!!['zip', 'x-7z', 'x-xz'].find(item => fileType.includes(item))
		) {
			return AiFillFileZip
		} else if (!!['plain'].find(item => fileType.includes(item))) {
			return AiFillFileText
		}
		return ImAttachment
	}

	useEffect(() => {
		if (!!fields[field.field_id] && fields[field.field_id].field_value) {
			let value = fields[field.field_id].field_value
			if (value.substr(0, 1) === '[') {
				setFiles(JSON.parse(value))
				onValidate(true)
			}
		}
	}, [fields[field.field_id]])
	const onChange = useCallback(
		file => {
			const { url } = file
			if (!!isRequired) {
				if (!!url.length) {
					onValidate(true)
				} else {
					onValidate(false)
				}
			} else {
				onValidate(true)
			}
			if (!!url) {
				let formatted = url.map(u => {
					var n = u.url.lastIndexOf('/')
					var file_name = n > -1 ? u.url.substr(n + 1, u.url.length) : ''
					var t = u.url.lastIndexOf('.')
					var file_type = t > -1 ? u.url.substr(t + 1, u.url.length) : ''

					return {
						file_name,
						file_url: u.url,
						file_type,
					}
				})

				setFiles(f => [...f, ...formatted])
				setCount(count + 1)
				dispatch({
					type: smartFormConst.SET_FIELD_VALUE,
					fieldId: field.field_id,
					formId: field.form_id,
					value: loadFinalValue(
						field,
						JSON.stringify([...files, ...formatted])
					),
				})
			}
		},
		[files]
	)

	const removeSelected = i => {
		files.splice(i, 1)
		setFiles(files)
		setCount(count + 1)
		dispatch({
			type: smartFormConst.SET_FIELD_VALUE,
			fieldId: field.field_id,
			formId: field.form_id,
			value: loadFinalValue(field, JSON.stringify(files)),
		})
	}

	const uploadFile = async file => {
		// const blob = file.slice(0, file.size, file.type)
		// const newFile = new File([blob], file.name.replace(/ /gi, '-'), {
		// 	type: blob.type,
		// })
		// let result = await pushFileToS3(newFile)
		// isFileUpload(false)
		// setIsUpload(false)
		// onError('')
		// const url = await Promise.all([result])
		// onChange({ url: url, isValid: true, msg: '' })
		// multiplle uploads
		const pFiles = file.map(async file => {
			const blob = file.slice(0, file.size, file.type)
			const newFile = new File([blob], file.name.replace(/ /gi, '-'), {
				type: blob.type,
			})
			let result = ''
			if (
				field?.field_inline_data &&
				field.field_inline_data.flag_public_access === 1
			) {
				let getBucketData = new Promise((resolve, reject) => {
					mutate(
						{ device_os_id: 5 },
						{
							onSuccess: async data => {
								resolve(data)
							},
						}
					)
				})
				await getBucketData.then(async res => {
					if (res && res.length > 0) console.log(res, 'res data  ')
					result = await pushFileToS3(newFile, '', res[0].bucket_name)
				})
			} else {
				result = await pushFileToS3(newFile)
			}
			isFileUpload(false)
			setIsUpload(false)
			return result
		})

		const urls = await Promise.all(pFiles)
		onChange({ url: urls, isValid: true, msg: '' })
	}

	const validateExcel = file => {
		isFileUpload(true)
		setIsUpload(true)
		onError('')
		mutate(
			{ bot_id: 0, form_id: field.form_id, field_id: field.field_id },
			{
				onSuccess: async res => {
					const {
						data: { status, response },
					} = res
					if (status === 200) {
						await dropBoxValidateExcelBot({
							schema: response.bot_operations.validate_excel,
							file: file,
							fieldList: fields,
							onSuccess: uploadFile,
							onError: () => {
								isFileUpload(false)
								setIsUpload(false)
								onError('Uploaded Excel is invalid. Please upload valid Excel')
								onOpen()
							},
							toggleLoader: () => {
								console.log('toggleloader')
							},
						})
						// TODO: on Single Organization login directly.
					}
				},
			}
		)
	}

	let supportedFiles
	let minsize = 0
	let maxsize = 52428800
	if (Object.keys(field.field_inline_data).length > 0) {
		if (field.field_inline_data.file_type_restriction_enabled === 1) {
			supportedFiles = field.field_inline_data.file_type_restriction.join(', ')
		}
		if (field.field_inline_data.file_size_restriction_enable === 1) {
			maxsize = Number(field.field_inline_data.file_size_restriction * 1048576)
		}
	}

	return (
		<>
			{isUpload ? (
				<Progress
					mx={2}
					colorScheme={localStorage.getItem('color')}
					size='sm'
					isIndeterminate
				/>
			) : (
				<React.Fragment>
					<AlertDialog
						isOpen={isOpen}
						leastDestructiveRef={cancelRef}
						onClose={onClose}
					>
						<AlertDialogOverlay>
							<AlertDialogContent borderRadius='sm'>
								<AlertDialogHeader fontSize='lg' fontWeight='bold'>
									{locale['Error']}
								</AlertDialogHeader>
								<AlertDialogBody>
									{locale['This field accepts only excels other than BC excel']}
								</AlertDialogBody>
								<AlertDialogFooter>
									<Button
										ref={cancelRef}
										borderRadius='sm'
										size='sm'
										variant='solid'
										colorScheme={localStorage.getItem('color')}
										bg={localStorage.getItem('color')}
										onClick={e => {
											sectionDetailedTrack({
												category: GA_CATEGORY_CUSTOM_INPUT,
												action: 'Dropbox Document',
												label: 'Closed',
											})
											onClose(e)
										}}
									>
										{locale['Cancel']}
									</Button>
								</AlertDialogFooter>
							</AlertDialogContent>
						</AlertDialogOverlay>
					</AlertDialog>
					<FileInput
						className={`dropbox-document-field-${toSnakeCase(
							'' + field.field_id
						)} ${isDisabled ? 'inactive' : 'active'}`}
						field={field}
						onChange={onChange}
						isDisabled={isDisabled}
						placeholder={supportedFiles}
						supportedFiles={supportedFiles}
						isFileUpload={isFileUpload}
						onReset={() => {
							onValidate(false)
						}}
						onError={onError}
						minsize={minsize}
						maxsize={maxsize}
						onExcelValidation={validateExcel}
						isMultiple={true}
					/>
				</React.Fragment>
			)}

			{Array.isArray(files) &&
				files.length > 0 &&
				Array.isArray(files) &&
				files.map((file, i) => (
					<>
						<Flex
							className={`dropbox-document-file-list`}
							w='80%'
							border='2px'
							borderColor='brand.800'
							borderStyle='solid'
							borderRadius='10px'
							py={2}
							px={5}
							my={2}
							mx='auto'
						>
							<Box w='full' className={`dropbox-document-file-list-container`}>
								<HStack
									w='full'
									justifyContent='space-between'
									className={`dropbox-document-file-${toSnakeCase(
										file.file_name
									)}`}
								>
									<Box display='flex' alignItems='center'>
										<Icon
											color={localStorage.getItem('color')}
											as={getFileType(file)}
											w={8}
											h={8}
											marginRight={6}
										/>
										<Text
											className={`dropbox-document-name-text-${toSnakeCase(
												file.file_name
											)}`}
											fontSize={16}
										>
											{file.file_name}
										</Text>
										<Text
											className={`dropbox-document-type-text-${getFileType(
												file
											)}`}
										>
											{getFileType(file)}
										</Text>
									</Box>
									<Box>
										<IconButton
											className={`dropbox-document-close-icon-button-${toSnakeCase(
												file.file_name
											)}`}
											bg='white'
											borderRadius='md'
											boxShadow='md'
											aria-label='More'
											marginRight='2'
											size='sm'
											_focus={{
												bg: 'secondary',
											}}
											_hover={{
												bg: 'secondary',
											}}
											// isDisabled={isDisabled}
											icon={<Icon as={MdCloudDownload} w={6} h={6} />}
											onClick={() => {
												sectionDetailedTrack({
													category: GA_CATEGORY_CUSTOM_INPUT,
													action: 'Dropbox Document',
													label: 'Download AWS file',
												})
												downloadAwsFile(file.file_url)
											}}
										/>
										<IconButton
											className={`dropbox-document-close-icon-button-${toSnakeCase(
												file.file_name
											)}`}
											bg='white'
											borderRadius='md'
											boxShadow='md'
											aria-label='More'
											size='sm'
											_focus={{
												bg: 'secondary',
											}}
											_hover={{
												bg: 'secondary',
											}}
											isDisabled={isDisabled}
											icon={<Icon as={MdClose} w={6} h={6} />}
											onClick={() => {
												sectionDetailedTrack({
													category: GA_CATEGORY_CUSTOM_INPUT,
													action: 'Dropbox Document',
													label: 'Remove Selected File',
												})
												removeSelected(i)
											}}
										/>
									</Box>
								</HStack>
							</Box>
						</Flex>
					</>
				))}
		</>
	)
}

export default DropboxDocumentField
