/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import FileInput from '../../../../fileInput';
import { useSmartFormContext } from '../../../../../context/smart-form.context';
import { smartFormConst } from '../../../../../utils/action.constant';
import { loadFinalValue } from '../../../../../utils/form.util';
import { toSnakeCase } from '../../../../../utils/common.util';
/**
 *  dataTypeId 32
 *  dataTypeName PDF Document
 *
 * */
const supportedFiles = ['application/pdf'];
const PdfDocument = ({
  field,
  isDisabled = true,
  isRequired,
  onValidate,
  onError,
  isFileUpload,
  onDelete,
}) => {
  const {
    state: { fields: formFields },
    dispatch,
  } = useSmartFormContext();
  const fields = !!formFields[field.form_id] ? formFields[field.form_id] : {};
  const onChange = url => {
    if (!!isRequired) {
      if (!!url) {
        onValidate(true);
      } else {
        onValidate(false);
      }
    } else {
      onValidate(true);
    }
    dispatch({
      type: smartFormConst.SET_FIELD_VALUE,
      fieldId: field.field_id,
      formId: field.form_id,
      value: loadFinalValue(field, url.url),
    });
  };
  useEffect(() => {
    if (!!fields[field.field_id] && fields[field.field_id].field_value !== '') {
      onChange({
        url: fields[field.field_id].field_value,
      });
    }
  }, []);
  return (
    <FileInput
      className={`pdf-document-field-${toSnakeCase(field.field_name)}`}
      field={field}
      onChange={onChange}
      isDisabled={isDisabled}
      placeholder=".pdf"
      supportedFiles={supportedFiles}
      value={fields[field.field_id] ? fields[field.field_id].field_value : ''}
      onError={onError}
      isFileUpload={isFileUpload}
      onReset={() => {
        onValidate(false);
        onError('');
      }}
      onDelete={onDelete}
    />
  );
};

export default PdfDocument;
